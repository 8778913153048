var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v("Organization Settings"),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tab", [_vm._v("General settings")]),
              _c("v-tab", [_vm._v("Secrets")]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c("v-card-title", [
                        _c(
                          "span",
                          { staticClass: "secondary--text font-weight-bold" },
                          [_vm._v("General settings")]
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c("h3", [
                                  _vm._v(
                                    "Features enabled here will be available for all spaces created in this organization."
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            _vm._l(_vm.features, function (feature, index) {
                              return _c(
                                "v-col",
                                {
                                  key: index,
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "4",
                                    xl: "3",
                                  },
                                },
                                [
                                  _c("org-feature-status", {
                                    attrs: { feature: feature },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [_c("the-secrets-listing", { attrs: { level: 3 } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }