var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "ma-1", attrs: { flat: "", elevation: "3" } },
    [
      _c(
        "v-list",
        { attrs: { "three-line": "", color: "transparent" } },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-avatar",
                [
                  !_vm.feature.error && !_vm.feature.disabledReason
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "primary lighten-1",
                          attrs: { dark: "" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.feature.enabled ? "check_circle" : "circle"
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c(
                        "v-icon",
                        { staticClass: "error lighten-1", attrs: { dark: "" } },
                        [_vm._v("error")]
                      ),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [_vm._v(_vm._s(_vm.feature.label))]),
                  _c("v-list-item-subtitle", [
                    _vm._v(_vm._s(_vm.feature.description)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.feature.enabled && _vm.feature.disabledReason && !_vm.feature.error
        ? _c("v-card-text", { staticClass: "warning--text pt-0" }, [
            _vm._v(
              " Your current plan does not allow using " +
                _vm._s(_vm.feature.label) +
                " feature. Please reach out to " +
                _vm._s(_vm.whoEnablesFeature[_vm.feature.disabledReason]) +
                " if you require this feature. "
            ),
          ])
        : _vm._e(),
      _vm.feature.error
        ? _c("v-card-text", { staticClass: "error--text pt-0" }, [
            _vm._v(
              "There was an error while getting the status of " +
                _vm._s(_vm.feature.label) +
                " feature."
            ),
          ])
        : _vm._e(),
      !_vm.feature.error && _vm.feature.canEnable
        ? _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              !_vm.feature.enabled && !_vm.feature.disabledReason
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        color: "primary",
                        loading: _vm.feature.loading,
                        disabled: !_vm.feature.canEnable,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.feature.fn(!_vm.feature.enabled)
                        },
                      },
                    },
                    [_vm._v(" Enable ")]
                  )
                : _vm._e(),
              _vm.feature.enabled
                ? [
                    _vm.isOrgManager && !_vm.enableOnly
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              color: "secondary",
                              loading: _vm.feature.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.feature.fn(!_vm.feature.enabled)
                              },
                            },
                          },
                          [_vm._v(" Disable ")]
                        )
                      : _vm._e(),
                    _vm.enableOnly
                      ? _c("v-btn", { attrs: { text: "", disabled: "" } }, [
                          _vm._v("Enabled"),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }